import React, { useState, useRef } from 'react'
import SquareWave from '../../components/square-wave/SquareWave'
// import IridescentBlob from '../../components/iridescent-blob/iridescent-blob';
import { Projects } from '../../components/projects/Projects'
import About from '../../components/about/About'

function Main() {
  const aboutButton = useRef(null)
  const projectsButton = useRef(null)
  const [showProjects, setShowProjects] = useState(false)
  const [showAbout, setShowAbout] = useState(false)
  const [triggerFade, setTriggerFade] = useState(false)

  const onButtonClick = (e) => {
    if (e.target.innerText === 'WORK') {
      if (window.innerWidth < 767) {
        setShowAbout(false)
      }
      if (!showProjects) {
        window.scrollTo(0, 0)
        setShowProjects(true)
      } else {
        setShowProjects(false)
      }
    } else if (e.target.innerText === 'SONIA BOLLER') {
      if (window.innerWidth < 767) {
        setShowProjects(false)
      }
      if (!showAbout) {
        setShowAbout(true)
      } else {
        setTriggerFade(true)
        setTimeout(function () {
          setShowAbout(false)
        }, 500)
        setTimeout(function () {
          setTriggerFade(false)
        }, 750)
      }
    }
  }
  return (
    <div id='main'>
      <SquareWave />
      {/* <IridescentBlob/> */}
      <div id='nav'>
        <button
          onClick={onButtonClick}
          className='glitch'
          data-text='Sonia Boller'
          ref={aboutButton}
        >
          Sonia Boller
        </button>
        <button
          onClick={onButtonClick}
          className='glitch'
          data-text='Work'
          ref={projectsButton}
        >
          Work
        </button>
      </div>
      <div id='content'>
        <About show={showAbout} fade={triggerFade} />
        <Projects show={showProjects} />
      </div>
    </div>
  )
}

export default Main
