import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import styles from './projects.module.scss'
import * as data from './projects.json'
import { Project, ProjectFull } from './Project'

export const ProjectsBlock = (props) => {
  const [showProject, setShowProject] = useState(false)
  const [project, setProject] = useState(true)
  const projectEl = useRef(null)

  const keyHandler = (e) => {
    if (e.keyCode === 27) {
      hideProject()
    }
  }

  const setProjectItem = (e) => {
    setShowProject(true)
    setProject(data.default[e])
  }

  const hideProject = (e) => {
    if (projectEl.current != null) {
      projectEl.current.classList.remove(styles.projectFullWrapperShow)
    }
    setTimeout(function () {
      setShowProject(false)
    }, 250)
  }

  useEffect(() => {
    let projects = document.querySelectorAll('.waterfall')

    function animateIn(item) {
      item.classList.add('waterfall-in')
    }

    function animateOut(item) {
      item.classList.remove('waterfall-in')
    }

    if (props.visible) {
      for (var i = 0; i < projects.length; i++) {
        setTimeout(animateIn.bind(undefined, projects[i]), i * 300)
      }
    } else if (!props.visible) {
      for (var j = 0; j < projects.length; j++) {
        setTimeout(animateOut.bind(undefined, projects[j]), j * 300)
      }
    }
  }, [props.visible])

  const handleAssetBlockScroll = (e) => {
    let h1 = document.querySelector('h1')
    if (e.target.scrollTop > 200) {
      h1.classList.add('fade-out')
    } else {
      h1.classList.remove('fade-out')
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', keyHandler)
    return () => {
      window.removeEventListener('keydown', keyHandler)
    }
  })

  useEffect(() => {
    if (projectEl.current != null) {
      projectEl.current.classList.add(styles.projectFullWrapperShow)
      projectEl.current.addEventListener('scroll', handleAssetBlockScroll)
    }
  }, [showProject])

  return (
    <React.Fragment>
      {Object.keys(data.default).map((projectItem, index) => {
        let project = data.default[projectItem]
        return (
          <div
            onClick={() => setProjectItem(projectItem)}
            key={index}
            className={classNames(styles.projectBlock, 'waterfall')}
          >
            <Project
              title={project.title}
              link={project.link}
              info={project.info}
              image={project.image}
            />
          </div>
        )
      })}
      {showProject && (
        <div className={classNames(styles.projectFullWrapper)} ref={projectEl}>
          <span
            className={classNames(styles.projectFullClose, 'material-icons')}
            onClick={hideProject}
          >
            close
          </span>
          <ProjectFull
            title={project.title}
            link={project.link}
            info={project.info}
            assets={project.assets}
            tech={project.tech}
            role={project.role}
            featured={project.featured}
          />
        </div>
      )}
    </React.Fragment>
  )
}

export const Projects = (props) => {
  return (
    <React.Fragment>
      <div className={classNames(styles.projectBlockContainer)}>
        <div
          className={classNames(
            styles.projectsBlock,
            props.show ? styles.projectsBlockShow : null
          )}
        >
          {props.show ? <ProjectsBlock visible={true} /> : <ProjectsBlock />}
        </div>
      </div>
    </React.Fragment>
  )
}
