import React from 'react'
import classNames from 'classnames'
import styles from './projects.module.scss'
import 'animate.css/animate.min.css'

export const Project = (props) => {
  return (
    <div className={classNames(styles.projectImageWrapper)}>
      <img
        src={props.image}
        alt={props.title + ' project image'}
        rel='preload'
      />
      <div>
        <p>{props.title}</p>
      </div>
    </div>
  )
}

export const ProjectFull = (props) => {
  return (
    <React.Fragment>
      <div className={classNames(styles.projectFullContainer)}>
        <div className={classNames(styles.projectInfoContainer)}>
          <h1>{props.title}</h1>
          <div className={classNames(styles.projectDescriptionContainer)}>
            <div>
              <h6>Role:</h6>
              <p className={classNames(styles.role)}>{props.role}</p>
            </div>
            <div>
              <h6>Descripton:</h6>
              <p>{props.info}</p>
              <span className={classNames(styles.projectInfoLinks)}>
                {props.link ? (
                  <a
                    href={props.link}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    explore
                  </a>
                ) : null}
                {props.featured ? (
                  <a
                    href={props.featured}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    featured
                  </a>
                ) : null}
              </span>
            </div>
            <div>
              <h6>{props.tech.length > 1 ? 'Technologies:' : 'Technology:'}</h6>
              {props.tech.map((tech, index) => {
                return <span key={index}>{tech}</span>
              })}
            </div>
          </div>
        </div>
        <span className={styles.projectAssetsWrapper}>
          <AssetsBlock data={props} />
        </span>
      </div>
    </React.Fragment>
  )
}

const AssetsBlock = (props) => {
  return (
    <div className={classNames(styles.projectAssetsBlock)}>
      {props.data.assets.map((asset, index) => {
        if (asset.type === 'mov' || asset.type === 'mp4') {
          if (asset.audio) {
            return (
              <span key={index}>
                <video
                  controls
                  loop
                  src={asset.link}
                  key={index}
                  rel='preload'
                  playsinline
                  webkit-playsinline
                />
                {asset.title ? <p>{asset.title}</p> : null}
              </span>
            )
          } else {
            return (
              <span key={index}>
                <video
                  autoPlay
                  muted
                  loop
                  src={asset.link}
                  key={index}
                  rel='preload'
                  playsInline={true}
                  webkit-playsinline='true'

                />
                {asset.title ? <p>{asset.title}</p> : null}
              </span>
            )
          }
        } else if (asset.type === 'img') {
          return (
            <span key={index}>
              <img src={asset.link} alt={asset.alt} rel='preload' />
              {asset.title ? <p>{asset.title}</p> : null}
            </span>
          )
        } else {
          return null
        }
      })}
    </div>
  )
}
