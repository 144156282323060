import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import Stats from 'three/examples/jsm/libs/stats.module'
import styles from './square-wave.module.scss';
import classNames from 'classnames';

const SquareWave = (props) => {
  const mount = useRef(null);
  useEffect(() => {
    let frameId;
    let xSeparation = 1.5,
      ySeparation = 1.5,
      xNum = 45,
      yNum = 45,
      mouseX = 0,
      mouseY = 0,
      width = mount.current.clientWidth,
      height = mount.current.clientHeight,
      windowHalfX = mount.current.clientWidth / 2,
      windowHalfY = mount.current.clientHeight / 2,
      angle = 8,
      countupPos = true,
      scale = 4,
      countupScale = true,
      speed = 5000

    if (window.innerWidth < 767) {
      mouseX = -200;
      mouseY = -200;
    }
    // let color = Math.round(Math.random());

    const fov = 25;
    const camera = new THREE.PerspectiveCamera(fov, width / height, 1, 10000);
    const scene = new THREE.Scene();
    const renderer = new THREE.WebGLRenderer({ antialias: true });

    camera.position.set(0, 0, 175);
    renderer.setSize(width, height);
    renderer.setClearColor(0x000000, 1);

    const renderScene = () => {
      renderer.render(scene, camera)
    }

    let particles = [];
    var i = 0;
    for (var iy = 0; iy < yNum; iy++) {
      for (var ix = 0; ix < xNum; ix++) {
        var material = new THREE.SpriteMaterial();
        var particle = particles[i++] = new THREE.Sprite(material);
        particle.position.x = ix * xSeparation - ((xNum * xSeparation) / 2);
        particle.position.y = iy * ySeparation - ((yNum * ySeparation) / 2);
        scene.add(particle);
      }
    }

    const handleResize = () => {
      width = mount.current.clientWidth;
      height = mount.current.clientHeight;
      windowHalfX = mount.current.clientWidth / 2;
      windowHalfY = mount.current.clientHeight / 2;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderScene();
    }

    const onDocumentMouseMove = (e) => {
      mouseX = e.clientX - windowHalfX;
      mouseY = e.clientY - windowHalfY;
    }

    const onDocumentTouchStart = (e) => {
      if (e.touches.length === 1) {
        mouseX = e.touches[0].pageX - (windowHalfX - 10);
        mouseY = e.touches[0].pageY - (windowHalfY - 10);
      }
    }

    const onDocumentTouchMove = (e) => {
      if (e.touches.length === 1) {
        mouseX = e.touches[0].pageX - (windowHalfX - 10);
        mouseY = e.touches[0].pageY - (windowHalfY - 10);
      }
    }

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousemove', onDocumentMouseMove, false);
    document.addEventListener('touchstart', onDocumentTouchStart, false);
    document.addEventListener('touchmove', onDocumentTouchMove, false);

    const oscillatePos = () => {
      if (countupPos) {
        angle += 0.0008;
        if (angle >= 9) {
          countupPos = false;
        }
      } else {
        angle -= 0.0008;
        if (angle <= 8) {
          countupPos = true;
        }
      }
    }

    const oscillateScale = () => {
      if (countupScale) {
        scale += 0.0004;
        if (scale >= 4.5) {
          countupScale = false;
        }
      } else {
        scale -= 0.0004;
        if (scale <= 4) {
          countupScale = true;
        }
      }
    }

    // var stats = new Stats()
    // document.body.appendChild(stats.dom)


    const animate = () => {
      frameId = window.requestAnimationFrame(animate);
      for (var j = 0; j < particles.length; j++) {
        var time = (speed + j) / 60;
        particle = particles[j];
        // if (color) {
        particle.position.z = 5 * Math.sin(time * angle);
        particle.material.color.r = Math.sin(time * (scale * 4));
        particle.material.color.g = Math.sin(time * (scale * 1));
        particle.material.color.b = Math.sin(time * (scale * 2));
        // } 
        // else {
        //   particle.position.z = 5 * Math.sin(time * angle);
        //   particle.material.color.r = 1;
        //   particle.material.color.g = 1;
        //   particle.material.color.b = 1;
        //   particle.scale.x = particle.scale.y = (Math.sin(time * scale));
        // }
      }

      camera.position.x += 2 * Math.cos(time) + (mouseX - camera.position.x) * 0.05;
      camera.position.y += 2 * Math.cos(time) + (-mouseY - camera.position.y) * 0.075;
      camera.lookAt(scene.position);
      // stats.update()
      oscillatePos();
      oscillateScale();
      renderScene();
    }

    const start = () => {
      if (!frameId) {
        frameId = requestAnimationFrame(animate);
      }
    }

    const stop = () => {
      cancelAnimationFrame(frameId);
      frameId = null;
    }

    mount.current.appendChild(renderer.domElement);
    window.addEventListener('resize', handleResize);
    start();
    const ref = mount.current;
    return () => {
      stop()
      window.removeEventListener('resize', handleResize);
      ref.removeChild(renderer.domElement);
      scene.remove(particles);
    }

  }, []);

  return (
    <div className={classNames(styles.squareWaveWrapper)} ref={mount} />
  )
}

export default SquareWave;