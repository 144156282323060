import React from 'react';
import {
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";
import './App.scss';
import Main from './pages/main/Main';
import HiNetflix from './pages/netflix/HiNetflix';

const router = createBrowserRouter([
	{
		path: "/",
		element: <Main />,
	},
	{
		path: "/hi-netflix",
		element: <HiNetflix />,
	},
]);

function App() {
	return (
		<React.StrictMode>
			<RouterProvider router={router} />
		</React.StrictMode>
	);
}

export default App;