import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import styles from './about.module.scss';

export const About = (props) => {
	const aboutEl = useRef(null);

	useEffect(() => {
		if(props.show && aboutEl.current != null){
			aboutEl.current.classList.add(styles.aboutBlockShow);
		}
	}, [props.show]);

	useEffect(() => {
		if(aboutEl.current != null){
			aboutEl.current.classList.remove(styles.aboutBlockShow);
		}
	}, [props.fade]);

	return (
		<React.Fragment>
			<div className={classNames(styles.aboutBlockContainer)}>
				{props.show && 
					<div className={classNames(styles.aboutBlock)} ref={aboutEl}>
						<div>
							is an LA based artist / creative technologist who formerly worked at <a href='https://www.stinkstudios.com/' target='_blank' rel='noopener noreferrer'>Stink Studios</a> and <a href='https://www.frog.co/' target='_blank' rel='noopener noreferrer'>frog design</a>, and is open to new opporunities!
						</div> 
						<div>
							Her background is in Environmental Studies, with a B.S. from USC, but during her last semester of college she fell in love with the unexpected visual nature of software engineering and ended up on this trajectory instead.
						</div> 
						<div>
							In 2018, she participated in a 10-week long artist run school / residency, called <a href='https://sfpc.io/' target='_blank' rel='noopener noreferrer'>SFPC</a> where she worked closely with others to explore the intersection of code, design, hardware and theory.
						</div> 
						<div>
							The majority of her work as of late focuses on the interaction between humans and the digital space, as well as the relationship between nature, music, and technology.
						</div> 
						<div className={classNames(styles.aboutLinks)}>
							<a href='./assets/media/Sonia_Boller_Resume_2023_April.pdf' target='_blank' rel='noopener noreferrer'>cv</a>
							<a href='https://github.com/soniaboller' target='_blank' rel='noopener noreferrer'>github</a>
							<a href='https://www.instagram.com/sboller/' target='_blank' rel='noopener noreferrer'>instagram</a>
							<a href='https://vsco.co/soniaboller/gallery' target='_blank' rel='noopener noreferrer'>photos</a>
							<a href='mailto:soniaboller@gmail.com'>hi</a>  
						</div> 
					</div>
				}
			</div>
		</React.Fragment>
	)
}

export default About;