// 3D model courtesy of https://sketchfab.com/3d-models/netflix-ca65870b36994d87aab2d10b115e2efb

import React from 'react'
import './style.css'
import * as THREE from 'three';
import netflixLogo from '../../assets/netflix.glb';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

function HiNetflix() {
    let camera, scene, renderer,
        light1, light2, light3, light4, light5, light6,
        object;

    // const clock = new THREE.Clock();

    init();
    animate();

    function init() {

        camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 1, 1000);
        camera.position.z = 150;
        camera.position.x = -10

        scene = new THREE.Scene();

        //model

        const loader = new GLTFLoader();
        loader.load(netflixLogo, function (obj) {
            console.log(obj)

            object = obj.scene;
            console.log(obj)
            object.scale.multiplyScalar(0.8);
            object.rotation.x = Math.PI / 2;
            scene.add(object);


        });

        const sphere = new THREE.SphereGeometry(0.5, 16, 8);

        //lights

        light1 = new THREE.PointLight(0xff0040, 2, 60);
        light1.add(new THREE.Mesh(sphere, new THREE.MeshBasicMaterial({ color: 0xff0040 })));
        scene.add(light1);

        light2 = new THREE.PointLight(0x0040ff, 2, 60);
        light2.add(new THREE.Mesh(sphere, new THREE.MeshBasicMaterial({ color: 0x0040ff })));
        scene.add(light2);

        light3 = new THREE.PointLight(0x80ff80, 2, 60);
        light3.add(new THREE.Mesh(sphere, new THREE.MeshBasicMaterial({ color: 0x80ff80 })));
        scene.add(light3);

        light4 = new THREE.PointLight(0xffaa00, 2, 60);
        light4.add(new THREE.Mesh(sphere, new THREE.MeshBasicMaterial({ color: 0xffaa00 })));
        scene.add(light4);

        light5 = new THREE.PointLight(0xa020f0, 2, 60);
        light5.add(new THREE.Mesh(sphere, new THREE.MeshBasicMaterial({ color: 0xa020f0 })));
        scene.add(light5);

        light6 = new THREE.PointLight(0xff00ff, 2, 60);
        light6.add(new THREE.Mesh(sphere, new THREE.MeshBasicMaterial({ color: 0xff00ff })));
        scene.add(light6);

        //renderer

        renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        document.body.appendChild(renderer.domElement);


        window.addEventListener('resize', onWindowResize);

    }

    function onWindowResize() {

        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(window.innerWidth, window.innerHeight);

    }

    function animate() {

        requestAnimationFrame(animate);

        render();

    }

    function render() {

        const time = Date.now() * 0.0005;
        // const delta = clock.getDelta();

        // if ( object ) object.rotation.x -= 0.5 * delta;

        light1.position.x = Math.sin(time * 0.7) * 60;
        light1.position.y = Math.cos(time * 0.5) * 40;
        light1.position.z = Math.cos(time * 0.3) * 30;

        light2.position.x = Math.cos(time * 0.2) * 60;
        light2.position.y = Math.sin(time * 0.6) * 40;
        light2.position.z = Math.sin(time * 0.8) * 30;

        light3.position.x = Math.sin(time * 0.8) * 60;
        light3.position.y = Math.cos(time * 0.2) * 40;
        light3.position.z = Math.sin(time * 0.5) * 30;

        light4.position.x = Math.sin(time * 0.3) * 60;
        light4.position.y = Math.cos(time * 0.8) * 40;
        light4.position.z = Math.sin(time * 0.6) * 30;

        light5.position.x = Math.cos(time * 0.5) * 60;
        light5.position.y = Math.cos(time * 0.8) * 40;
        light5.position.z = Math.sin(time * 0.3) * 30;

        light6.position.x = Math.cos(time * 0.6) * 60;
        light6.position.y = Math.sin(time * 0.3) * 40;
        light6.position.z = Math.cos(time * 0.7) * 30;

        renderer.render(scene, camera);

    }
    return (
        <canvas className="webgl"></canvas>
    )
}


export default HiNetflix